









import { defineComponent, PropType } from "@vue/composition-api";
import { mapActions } from "vuex";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";

export default defineComponent({
  name: "TableFileList",
  props: {
    files: {
      type: Array as PropType<Partial<{ id: number; filename: string; uuid: string }[]>>,
      default: () => [],
    },
  },
  data() {
    return {
      isShowAdditional: false,
    };
  },
  computed: {
    maxLength(): number {
      return this.files.length > 2 && !this.isShowAdditional ? 1 : this.files.length;
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    openFile(file: { id: number; filename: string; uuid: string }) {
      this.openNewWindow(`${getFullPath(QUERY_PATH.GET_RECEIPT_DOWNLOAD)}?uuid=${file.uuid}&origin=true`);
    },
  },
});
